/* body {
  font-family: '';
  font-size: 16px;
} */

@tailwind base;
@tailwind components;

@font-face {
    font-family: 'Graphik-Bold';
    src: local('Graphik-Bold'), url(./assets/fonts/Graphik-Bold.woff2) format('ttf');
}

@font-face {
    font-family: 'Graphik-Light';
    src: local('Graphik-Light'), url(./assets/fonts/Graphik-Light.woff2) format('ttf');
}

@font-face {
    font-family: 'Graphik-Medium';
    src: local('Graphik-Medium'), url(./assets/fonts/Graphik-Medium.woff2) format('ttf');
}

@font-face {
    font-family: 'Graphik-Regular';
    src: local('Graphik-Regular'), url(./assets/fonts/Graphik-Regular.woff2) format('ttf');
}

@font-face {
    font-family: 'Graphik-SemiBold';
    src: local('Graphik-SemiBold'), url(./assets/fonts/Graphik-SemiBold.woff2) format('ttf');
}

@font-face {
    font-family: 'Graphik-Thin';
    src: local('Graphik-Thin'), url(./assets/fonts/Graphik-Thin.woff2) format('ttf');
}

@tailwind utilities;

@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    img {
        @apply inline-block;
    }
}

html,
body {
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

/* for slider */
.slick-track {
    /* width: 352px !important; */
    align-items: stretch;
    display: flex !important;
}

.slick-slide {
    margin-right: 20px;
    max-width: 300px;
    height: unset;
}

.slick-slide > div {
    height: 100%;
}

/* inputs for safary */
input::-webkit-textfield-decoration-container,
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
}

.fileName {
    align-self: flex-end;
}

.fileName span {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.fileName span:first-child {
    width: 70px;
    text-overflow: ellipsis;
}

.fileName span + span {
    width: 40px;
    direction: rtl;
    text-align: right;
}

.lds-ring {
    position: relative;
    width: 24px;
    height: 24px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.iframe-player iframe {
    width: 100%;
    height: 100%;
}
